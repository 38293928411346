<template>
  <div class="page--home">
    <v-container>
      <v-row class="mt-10">
        <v-col class="text-center">
          <h1 class="text-h3 grey--text text--darken-3 font-weight-light text-uppercase">Olá {{ user.fullName }}</h1>
          <p class="mt-4 grey--text text--darken-3 font-weight-light">
            Escolha um das opções de menu e acesse 
            <br> as funcionalidades do {{ app.alias }}
          </p>
        </v-col>
      </v-row>
      <v-row></v-row>
    </v-container>
  </div>
</template>

<script>
import pageLoadingMixin from "mixins/page-loading";
import configurations from 'commons/configurations';
import { AUTH_NAMESPACE, USER, ABILITIES, MENU } from 'store/modules/auth';

export default {
  name: "Home",
  mixins: [pageLoadingMixin],
  components: {},
  data: () => ({
    app: {
      name: configurations.app.name,
      alias: configurations.app.alias
    }
  }),
  computed: {
    user() {
      return {
        ...this.$store.getters[`${AUTH_NAMESPACE}/${USER}`],
        permissions: this.$store.getters[`${AUTH_NAMESPACE}/${ABILITIES}`],
        menu: this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`]
      }
    },
    permissoes() {
      return this.$store.getters[`${AUTH_NAMESPACE}/${ABILITIES}`];
    }
  },
  methods: {
    verificacaoPermissaoTermo() {
      if(this.user.acceptedTerms === true) {
        this.$router.push('/app').catch(() => {});
      }
      else {
        this.$router.push('/consentimento-uso-de-dados-pessoais');
      }
    }
  },
  beforeMount() {
    this.hidePageLoading();
  },
  mounted() {
    this.verificacaoPermissaoTermo();
  }
};
</script>
